import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/project.css";

const Project = (props) => {
	const { logo, title, description, linkText, link, technologiesLogos } = props;

	return (
		<React.Fragment>
			<div className="project">
				<Link to={link ? link : ""} style={{ pointerEvents: !link && "none" }}>
					<div className="project-container">
						<div className="project-logo">
							<img src={logo} alt="logo" />
						</div>
						<div className="project-title">{title}</div>
						{/* <div className="project-description">{description}</div> */}
						{description.map((item, index) => (
							<div className="project-description" key={index}>
								{item}
							</div>
						))}
						{linkText && <div className="project-link">
							<div className="project-link-icon">
								<FontAwesomeIcon icon={faLink} />
							</div>

							<div className="project-link-text">{linkText}</div>
						</div>}
						<div className="technologies-container">
							{technologiesLogos.map((item, index) => (
								<div className="project-logo" key={index + "tech"}>
									<img src={item} alt="logo" />
								</div>
							))}
						</div>


					</div>
				</Link>
			</div>
		</React.Fragment>
	);
};

export default Project;
