const INFO = {
	main: {
		title: "Michael's Portfolio",
		name: "Michael K.",
		email: "mjk2970@yahoo.com",
		logo: "../michael_logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://www.linkedin.com/in/michael-kemper-929636ba/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Full-stack web app developer",
		description:
			"I am a full-stack developer with expertise in backend technologies such as Node.js, Express.js, and AWS. My frontend expertise includes React and the MUI library, along with many other additional libraries. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I’m Michael Kemper. I live in San Diego, CA, where I architect high quality applications.",
		description:
			"I've worked on many production-ready applications at the companies I've been at over the years and I'm proud of the progress I've made. Many of these projects are still used by people everyday. Programming has been a passion of mine since I was a teenager tinkering around with websites and creating web crawling bots, which drove my passion into the career of software engineering. I've been fortunate to work with many great companies using a wide array of technologies and languages to problem solve and come up with efficient and scalable solutions. I'm always expanding my skills with work experience and side projects that I am passionate about.",
	},

	projects: [
		{
			title: "Purposer",
			description: [
				`◦ ExpressJS Backend: Architected and implemented an ExpressJS serverless Lambda backend hosted in AWS. Created a MySQL database layer and a Redis caching layer for data retrieval of the RESTful API.`,
				`◦ React Frontend: Created reusable React functional components. Setup form validation and secure API calls within the codebase. Utilized the Material UI npm library for consistent stylized elements. Setup desktop and mobile friendly components. Created a custom file upload component for uploading multiple ordered photos.`,
				`◦ Security: Prioritized platform security by implementing an advanced backend firewall, meticulously designed to mitigate DDOS attacks and protect user data and platform integrity, while also applying common security rules and best practices to provide resilience against threats. Integrated JWT authentication for API calls.`,
				`◦ Payment Integration: Built a custom Stripe implementation to handle payments on the platform. Setup user checkout with itemized products created in the backend and visible on the frontend. Introduced management of seller and admin created discount codes and timed sales. Developed user initiated refunds, that are fully managed by individual sellers on the platform.`,
				`◦ NodeJS Shopify Integration: Created a Shopify webhook to sync product inventory counts across platforms. Optionally pushed incoming orders into the Shopify platform. Setup bulk product importer tool to expedite product creation.`,
				`◦ Team Leadership: Led the development team in stakeholder interactions to define technical requirements. Managed team Scrum meetings and code reviews, conducting troubleshooting and debug sessions to fill knowledge gaps. Focused on skill development, offering challenging opportunities for contractors and interns.`,
				`◦ Communications: Setup transactional emails with SendGrid to send buyers and sellers on the platform order related data. Forwarded marketing data to Klaviyo for lead generation and marketing email flows.`,
			],
			logo: "./purposer_logo.png",
			linkText: "View Website",
			link: "https://www.purposer.com/",
			technologiesLogos: [
				"https://www.cdnlogo.com/logos/n/79/node-js.svg",
				"https://www.cdnlogo.com/logos/r/63/react.svg",
				"https://www.cdnlogo.com/logos/a/19/aws.svg",
				"https://www.cdnlogo.com/logos/m/10/mysql.svg",
				"https://www.cdnlogo.com/logos/r/3/redis.svg",
				"https://www.cdnlogo.com/logos/s/51/stripe.svg",
				"https://www.cdnlogo.com/logos/s/48/sendgrid.svg",
				"https://www.cdnlogo.com/logos/s/18/shopify.svg",
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/typescript/typescript.png",
			],
		},

		{
			title: "Viasat",
			description: [
				`◦ Advanced Communications: Designed and developed a communications platform with Typescript that sent out SMS with Twilio, push notifications with Firebase, and emails with Marketo. Utilized serverless processing to scale with surges of 100k+ event invocations. Developed a user-friendly React/Next.js UI front-end that streamlined the configuration of communications, allowing business stakeholders to seamlessly edit outgoing messages. Built an alternative channel for impromptu bulk communications to be sent out for service outages and satellite updates.`,
				`◦ International: Created communications and worked with the business teams within the United States, Latin America, and Europe. Worked with legal teams to follow local communication laws and ensure PII standards for GDPR were met.`,
				`◦ Microservices: Designed and developed a suite of GraphQL API microservices with Typescript hosted on AWS Lambda that enabled configuration of reusuable communications, dynamic data elements, and outgoing templates. Created a customer communication preference service that allowed multiple teams to efficiently manage and interact with communication preferences.`,
				`◦ Detailed Logging: Implemented a CloudWatch logging system for actionable, chronological event analysis. Generated and sent event statistics to appropriate teams.`,
				`◦ Redundancy: Ensured continuous communication flow by managing SNS topics with layered SQS queues with exponential backoff. Used MySQL on RDS as a database service for reliably storing records, and DynamoDB for efficient key-value storage related to customer preferences. Utilized Redis caching to save on redundant API calls, resulting in cache hits in over 50% of cases.`,
				`◦ Deployment: Created an AWS CodePipeline CICD workflow that deployed our pre-production and production backends when a change was made to their respective branches on GitHub. Collaborated closely with the QA team to ensure that all deployments met our rigorous quality standards.`,
			],
			logo: "./viasat_logo.png",
			technologiesLogos: [
				"https://www.cdnlogo.com/logos/n/79/node-js.svg",
				"https://www.cdnlogo.com/logos/r/63/react.svg",
				"https://www.cdnlogo.com/logos/n/80/next-js.svg",
				"https://www.cdnlogo.com/logos/g/23/graphql.svg",
				"https://www.cdnlogo.com/logos/a/19/aws.svg",
				"https://www.cdnlogo.com/logos/m/10/mysql.svg",
				"https://www.cdnlogo.com/logos/r/3/redis.svg",
				"https://www.cdnlogo.com/logos/f/48/firebase.svg",
				"https://www.cdnlogo.com/logos/t/98/twilio.svg",
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/typescript/typescript.png",
			],
			// linkText: "View Project",
			// link: "https://github.com",
		},

		// {
		// 	title: "Project 3",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 4",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 5",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },
	],
};

export default INFO;
